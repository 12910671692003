body {
  color: #333;
  font-family: sans-serif;
}

.title {
  margin-bottom: 2rem;
  font-size: large;
}

#message {
  color: #8b0000;
}

/*# sourceMappingURL=index.734bb7b4.css.map */
