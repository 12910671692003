body {
	font-family: sans-serif;
	color: #333;
}

.title {
	font-size: large;
	margin-bottom: 2rem;
}

#message {
	color: darkred;
}